import React from "react"
import LayoutCourse from "../../../../../layouts/course"
import {
  getCourseNavigation,
  useCourseStore,
} from "../../../../../store/courses"

import Paragraph from "../../../../../components/paragraph"
import CourseFooterNext from "../../../../../components/course-footer-next"
import HandDrawing from "../../../../../components/hand-drawing"
import Heading from "../../../../../components/heading"
import Note from "../../../../../components/note"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"

import SampleImage from "../../../../../images/kurse/titel-heidelberg.png"

const Page = () => {
  const { setAnswer } = useCourseStore()
  const navigation = getCourseNavigation({ courseId: "hoelderlins-reisen" })

  return (
    <LayoutCourse
      footer={
        <CourseFooterNext to="/kurse/hoelderlins-reisen/02-reiseerinnerung/handschrift-entziffern/stadt" />
      }
      navigation={navigation}
    >
      <Seo title="Hölderlins Handschrift entziffern" />
      <Stack>
        <Heading as="h2" level={2}>
          Hölderlins Handschrift entziffern
        </Heading>
        <Paragraph>
          Wie alle seine Gedichte hat Hölderlin auch dieses Gedicht mit der Hand
          geschrieben, denn Computer gab es zu seiner Zeit noch gar nicht.
          Hölderlin schrieb mit einer Feder, die er beim Schreiben regelmäßig in
          ein Tintenfass tunken musste. Deshalb gibt es auf seinen Blättern auch
          so viele Tintenkleckse. So ein von Hand beschriebenes Papier nennt man
          Manuskript – vom latenischen manu (Hand) scriptum (Geschriebenes).
        </Paragraph>
        <Note variant="task">
          <Stack space={6}>
            <p>
              Versuche den Titel des Gedichts in der Handschrift nachzufahren.
              Er verrät dir, um welche Stadt es hier geht.
            </p>
            <p>
              Auf dem Handy oder Tablet kannst du dafür deinen Finger verwenden.
              Am Computer hältst du die linke Maustaste gedrückt und ziehst den
              Mauszeiger über das Bild.
            </p>
          </Stack>
        </Note>
        <HandDrawing
          image={SampleImage}
          ratio={0.4}
          onChange={(ref) => {
            setAnswer({
              courseId: "hoelderlins-reisen",
              chapterId: "02-reiseerinnerung",
              taskId: "handschrift-entziffern-schrift",
              answer: ref.getSaveData(),
            })
          }}
        />
      </Stack>
    </LayoutCourse>
  )
}

export default Page
